* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

body {
  display: flex;
  justify-content: center; 
  align-items: center; 
  margin: 0;
  padding: 0;
  background-color: rgb(244, 236, 217);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  max-width: 768px;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  /* border: 1px solid black; */
}

.screen {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 50%; */
  align-items: center;
}

.barxp {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.buttonsInterface {
  display:flex;
  margin: 0.5em;
  width: 100%;
  justify-content: space-around;
}

.canvas1 {
  display: flex;
  /* position: relative; */
  /* border: 5px solid black; */
  /* top: 0%;
  left: 50%;
  translate: -50%; */
 
  width: 100%;
  /* height: 33%; */
}

.shop {
  display: flex;
  flex-direction: column;
 
  /* border: 3px solid #000000; */
  border-radius: 5px;
  width: 100%;
  /* height: 100%; */
  overflow-y: auto;

}

.shop-title {
  border: 0.08em solid rgb(0, 0, 0);
  background-color: #67cbc3;
  border-radius: 0.5em;
  margin: 1em;
  text-align: center;
}

.shop-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.shop-item {
  display: flex;
  /* width: 10em; 
  height: 10em; */
  width: 40%;
  /* max-height: 20em; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0.08em solid black;
  background-color: rgb(250, 215, 160);
  color: black;
  cursor: pointer;
  border-radius: 0.3em;
  margin: 0.5em;
  padding: 0.5em; 
}

.inventory {
  display: flex;

}

.inventory-item {
  display: flex;
  /* width: 10em; 
  height: 10em; */
  /* max-width: 40%; */
  /* max-height: 20em; */
  /* flex-direction: column; */
  /* width: auto; */
  /* height: auto; */
  align-items: center;
  /* justify-content: center; */
  border: 0.08em solid black;
  background-color: rgb(250, 215, 160);
  color: black;
  cursor: pointer;
  border-radius: 0.3em;
  margin: 0.5em;
  padding: 0.5em; 
}

.inventory-item img {
  image-rendering: pixelated;
}

.inventory-item img {
  image-rendering: pixelated;
}

.owned {
  background-color: lightgreen; /* Change to your desired background color */
  cursor: auto;
}

.slots {
  display: flex;
}